import { Component, HostListener, OnInit } from '@angular/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { NavigationEnd, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Location } from '@angular/common';

import { UserSidebarModalComponent } from './user-sidebar-modal/user-sidebar-modal.component';

@Component({
  selector: 'app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  protected isMobileView: boolean = false;

  protected firstLayer: boolean;
  protected hideHeader: boolean = false;
  protected deepDiveTitle: string;
  protected isOpen: boolean = false;

  constructor(
    private _localeService: BsLocaleService,
    private readonly router: Router,
    public location: Location,
    private bsModalService: BsModalService,
  ) {
    this._localeService.use('de');
    this.windowSize();
  }

  @HostListener('window:resize', ['$event'])
  protected onResize(event): void {
    this.windowSize();
  }

  private windowSize(): void {
    this.isMobileView = window.innerWidth <= 750;
    this.changeActiveNavigationView(this.location);
  }

  public ngOnInit(): void {
    this.changeActiveNavigationView(this.location);

    this.router.events.subscribe({
      next: (event): void => {
        if (event instanceof NavigationEnd) {
          if (event.url === '/') {
            this.router.navigateByUrl('dashboard');
          } else {
            this.changeActiveNavigationView(this.location);
          }
        }
      },
      error: (error): void => {
        console.log(error);
      },
    });

    if (this.router.url === '/') {
      this.router.navigateByUrl('dashboard');
    }
  }

  private changeActiveNavigationView(location: Location): void {
    let [pathname, search = ''] = location.path(false).split('?');

    if (!pathname) {
      pathname = this.router.url;
    }

    if (pathname.includes('news/') && pathname.includes('vinhub')) {
      this.firstLayer = window.innerWidth > 800 && window.innerWidth < 950;
    } else if (
      pathname.includes('dashboard') ||
      pathname.includes('activity') ||
      pathname.includes('clients') ||
      pathname.includes('news') ||
      pathname.includes('campaign') ||
      pathname.includes('contracts-overview') ||
      pathname.includes('media-studio') ||
      pathname.includes('analyse') ||
      pathname.includes('calendar') ||
      pathname.includes('/settings') ||
      pathname.includes('/profile-tab/company-settings') ||
      pathname.includes('/mandate/mandate-form') ||
      pathname.includes('/pools') ||
      pathname.includes('/subscription-manager/abonament-manager') ||
      pathname.includes('/subscription-manager/payment-manager') ||
      pathname.includes('/subscription-manager/payment-invoices-overview') ||
      pathname.includes('/data-advisors-table') ||
      pathname.includes('/broker-mandate-approvals')
    ) {
      this.firstLayer = true;
    } else {
      this.firstLayer = false;
    }

    this.hideHeader = pathname.includes('vinhub');

    if (
      pathname.includes('/bank-card-manager/') &&
      !this.firstLayer &&
      !pathname.includes('alerts') &&
      !pathname.includes('/single-analyse') &&
      !pathname.includes('/tags-manager')
    ) {
      this.deepDiveTitle = '';
    }

    if (pathname.includes('dashboard')) this.deepDiveTitle = 'Dashboard';
    if (pathname.includes('analyse')) this.deepDiveTitle = 'Analysen';
    if (pathname.includes('clients')) this.deepDiveTitle = 'Kundenliste';
    if (pathname.includes('news')) this.deepDiveTitle = 'Nachrichten';
    if (pathname.includes('campaign/overview')) this.deepDiveTitle = 'Kampagnen';
    if (pathname.includes('campaign/filter-groups')) this.deepDiveTitle = 'Filter';
    if (pathname.includes('campaign/filter-groups/create-filter-group-block')) this.deepDiveTitle = 'Filter erstellen';
    if (pathname.includes('campaign/create-campaign')) this.deepDiveTitle = 'Kampagne erstellen';
    if (pathname.includes('campaign/create-campaign/vinhub')) this.deepDiveTitle = 'Kampagne senden';
    if (pathname.includes('campaign/create-campaign/media')) this.deepDiveTitle = 'Mediakampagne senden';
    if (pathname.includes('campaign/create-campaign/vinhub/filter/')) this.deepDiveTitle = 'Dashboard filter';
    if (pathname.includes('campaign/list-sent/show-campaign/vinhub/')) this.deepDiveTitle = 'Kampagne senden';
    if (pathname.includes('campaign/list-sent')) this.deepDiveTitle = 'Kampagnen versendet';
    if (pathname.includes('campaign/list-draft')) this.deepDiveTitle = 'Kampagnen Entwürfe';
    if (pathname.includes('contracts-overview')) this.deepDiveTitle = 'Versicherungen';
    // if (pathname.includes('pool-connection')) this.deepDiveTitle = 'Vinsafes eröffnen';
    if (pathname.includes('calendar')) this.deepDiveTitle = 'Kalender';
    if (pathname.includes('activity')) this.deepDiveTitle = 'Aktivitäten';
    if (pathname.includes('settings')) this.deepDiveTitle = 'Einstellungen';
    if (pathname.includes('subscription-manager')) this.deepDiveTitle = 'Abonnementverwaltung';
    if (pathname.includes('subscription-creation')) this.deepDiveTitle = 'Nutzer erstellen';
    if (pathname.includes('subscription-edit')) this.deepDiveTitle = 'Nutzer Bearbeiten';
    if (pathname.includes('subscription-payment-error')) this.deepDiveTitle = 'Payment error';
    if (pathname.includes('mandate')) this.deepDiveTitle = 'Mandate';
    if (pathname.includes('person-profile')) this.deepDiveTitle = 'Beraterprofil';
    if (pathname.includes('profile-tab')) this.deepDiveTitle = 'Unternehmen';
    if (pathname.includes('pool-connection')) this.deepDiveTitle = 'Maklerpool';
    if (pathname.includes('media-studio/video-library')) this.deepDiveTitle = 'Media Studio';
    if (pathname.includes('media-studio/audio-library')) this.deepDiveTitle = 'Audio Studio';
    if (pathname.includes('media-studio/video-creation')) this.deepDiveTitle = 'Video erstellen';
    if (pathname.includes('media-studio/video-edit/')) this.deepDiveTitle = 'Video bearbeiten';
    if (pathname.includes('media-studio/audio-creation')) this.deepDiveTitle = 'Audio erstellen';
    if (pathname.includes('media-studio/audio-edit/')) this.deepDiveTitle = 'Audio bearbeiten';
    // if (pathname.includes('my-network')) this.deepDiveTitle = 'Tags manager';
    if (pathname.includes('broker-mandate-approvals')) this.deepDiveTitle = 'Maklerverträge';
    if (pathname.includes('clients/detail/') && pathname.includes('info')) this.deepDiveTitle = 'Kunde';
    if (pathname.includes('clients/detail/') && pathname.includes('vinhub')) this.deepDiveTitle = 'Chats';
    if (pathname.includes('clients/detail/') && pathname.includes('contracts')) this.deepDiveTitle = 'Vertrag';
    if (pathname.includes('clients/detail/') && pathname.includes('events')) this.deepDiveTitle = 'Events';
    if (pathname.includes('clients/detail/') && pathname.includes('resources')) this.deepDiveTitle = 'Ressourcen';
    if (pathname.includes('clients/detail/') && pathname.includes('contracts/contract'))
      this.deepDiveTitle = 'Vertragsdetails';
  }

  protected showMobileUserSidebar(sidebarState: boolean): void {
    if (this.isMobileView) {
      this.bsModalService.show(UserSidebarModalComponent, {});
    } else {
      this.isOpen = sidebarState;
    }
  }

  protected hideMobileUserSidebar(sidebarState: boolean): void {
    this.isOpen = sidebarState;
  }
}
