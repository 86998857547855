import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { WebSocketService } from '@vinlivt/websocket';
import WithSubscription from '../../utils/WithSubscription';
import { PoolImporterService } from '../../service/pool-importer.service';
import { Advisor, PoolConnection } from '../../model';
import { AdvisorsService, PoolService } from '../../service';
import { FondsfinanzLoginResponse } from '../../model/pool/fondsfinanz-login-response';
import { BrokerPoolService } from '../../service/broker-pool.service';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-pool-synch-modal',
  standalone: true,
  imports: [],
  templateUrl: './pool-synch-modal.component.html',
  styleUrl: './pool-synch-modal.component.scss',
})
export class PoolSynchModalComponent extends WithSubscription(Object) implements OnInit {
  protected status: string = null;
  protected poolConnection: PoolConnection = new PoolConnection();
  protected advisor: Advisor = new Advisor();
  protected popupWindow: Window | null = null;

  constructor(
    private readonly poolService: PoolService,
    private readonly brokerPoolService: BrokerPoolService,
    private readonly advisorsService: AdvisorsService,
    private readonly poolImporterService: PoolImporterService,
    private readonly toastrService: ToastrService,
    private readonly modalService: BsModalService,
    private readonly webSocketService: WebSocketService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.loadUserProfile();
    this.listenForNewMessages();
  }

  protected loadStatus(): void {
    this.poolImporterService.status().subscribe({
      next: (response: any): void => {
        this.status = response.value;
      },
      error: (error: Error): void => {
        console.log(error);
      },
    });
  }

  private loadUserProfile(): void {
    this.advisorsService.getAdvisorProfile().subscribe({
      next: (data: Advisor): void => {
        this.advisor = data;
        this.loadPoolConnection(this.advisor.uid);
      },
      error: (error): void => {},
    });
  }

  private loadPoolConnection(keycloakId: string): void {
    this.poolService.loadPoolConnection(keycloakId).subscribe({
      next: (data: PoolConnection): void => {
        if (data) {
          this.poolConnection = data;
          if (this.poolConnection?.pool?.name === 'FondsFinanz') {
            this.checkFondsFinanzAuth();
          } else {
            this.checkNetfondsPool();
          }
        } else {
          this.poolConnection = new PoolConnection();
        }
      },
      error: (error): void => {
        console.log(error);
      },
    });
  }

  private checkNetfondsPool(): void {
    if (this.poolConnection?.username && this.poolConnection?.password) {
      this.poolService.testConnection(this.poolConnection).subscribe({
        next: (): void => {
          this.loadStatus();
        },
        error: (error): void => {
          console.log(error);
        },
      });
    }
  }

  protected openFondsFinanzAuth(loginUrl: string): void {
    // Desired window size
    const width = 450;
    const height = 500;

    // Calculate the position to center the window
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;

    this.popupWindow = window.open(
      loginUrl,
      '_blank',
      `width=${width},height=${height},top=${top},left=${left},resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no,status=no`,
    );
  }

  protected checkFondsFinanzAuth(): void {
    this.brokerPoolService.validateToken(this.advisor.uid).subscribe({
      // return with status code
      next: (res): void => {
        console.log('No response code');
        this.loadStatus();
      },
      error: (e: any): void => {
        this.brokerPoolService.requestLoginUrl(this.advisor.uid).subscribe({
          next: (res: FondsfinanzLoginResponse): void => {
            this.openFondsFinanzAuth(res.url);
          },
          error: (err): void => {},
        });
      },
    });
  }

  protected synchronisePoolData(): void {
    const confirmationModalRef = this.modalService.show(ConfirmationModalComponent, {});
    confirmationModalRef.content.confirmationTitle = 'Bestätigung';
    confirmationModalRef.content.confirmationMessage = 'Sind Sie sicher, dass Sie die Daten synchronisieren wollen?';
    confirmationModalRef.content.actionLabelBtn = 'Synchronisieren';

    confirmationModalRef.content.action.subscribe((value: boolean): void => {
      if (value) {
        if (this.poolConnection?.pool?.name === 'FondsFinanz') {
          // FondsFinanz
          this.brokerPoolService.synchroniseData().subscribe({
            next: (res): void => {
              confirmationModalRef.hide();
              this.toastrService.info('Synchronisierung erfolgreich gestartet!');
            },
            error: (e: any): void => {},
          });
        } else {
          // Netfonds
          this.poolService.importCustomers().subscribe({
            next: (res): void => {
              confirmationModalRef.hide();
              this.toastrService.info('Synchronisierung erfolgreich gestartet!');
            },
            error: (e: any): void => {},
          });
        }
      } else {
        confirmationModalRef.hide();
      }
    });
  }

  private listenForNewMessages(): void {
    this.listenToSubject(
      this.webSocketService.subscribe<string>('legacy/brokerpool/finished-synch'),
      (status: string): void => {
        if (status === 'INTERRUPTED') {
          this.toastrService.error('Fehler beim Synchronisieren der Daten vom Pool.');
        }
        if (status === 'FINISHED') {
          this.toastrService.info('Synchronisierung der Daten vom Pool erfolgreich beendet.');
        }
        this.loadStatus();
      },
    );
  }

  poolAction() {
    if (this.status === 'FINISHED' || this.status === '' || this.status === null || this.status === 'INTERRUPTED') {
      this.synchronisePoolData();
    }
  }
}
