import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, Subscriber } from 'rxjs';

import { environment } from '../../../environments/environment';

import {
  VinhubAttachment,
  VinhubChatHistoryRequest,
  VinhubChatHistoryResponse,
  VinhubChatMessages,
  VinhubChatResponse,
  VinhubSendMessage,
} from '../model';

@Injectable({
  providedIn: 'root',
})
export class VinhubService {
  constructor(private http: HttpClient) {}

  public getChatList(page: number = 0, page_size: number = 50, participant?: string): Observable<VinhubChatResponse> {
    let params: HttpParams = new HttpParams();

    // Add page if available
    if (page) {
      params = params.set('page', page.toString());
    }

    // Add pageSize if available
    if (page_size) {
      params = params.set('page_size', page_size.toString());
    }

    // Add participant if available
    if (participant) {
      params = params.set('participant', participant.toString());
    }

    let options: {} = { params };
    return this.http.get<VinhubChatResponse>(`${environment.apiEndpoint}/chat/b2b/entity`, options);
  }

  // Check how many unread messages
  public checkUnreadMessages(): Observable<number> {
    return this.http.get<number>(`${environment.apiEndpoint}/chat/b2b/entity/unread`);
  }

  public markChatAsRead(chatId: number): Observable<void> {
    return this.http.post<void>(`${environment.apiEndpoint}/chat/b2b/entity/${chatId}/read`, null);
  }

  public getChatMessages(
    chatId: number,
    nextToken?: string | null,
    type: 'TEXT' | 'ATTACHMENT' | 'VIDEO' = 'TEXT',
  ): Observable<VinhubChatMessages> {
    let params: HttpParams = new HttpParams();

    // Add nextToken if available
    if (nextToken) {
      params = params.set('next_token', nextToken);
    }

    if (type) {
      params = params.set('type', type);
    }

    let options: {} = { params };
    return this.http.get<VinhubChatMessages>(`${environment.apiEndpoint}/chat/b2b/message/${chatId}`, options);
  }

  public sendMessage(chatId: number, body: VinhubSendMessage): Observable<HttpResponse<any>> {
    return this.http.post<any>(`${environment.apiEndpoint}/chat/b2b/message/${chatId}`, body, { observe: 'response' });
  }

  public sendAttachments(url: string, attachment: File): Observable<any> {
    return new Observable<any>((observer: Subscriber<any>) => {
      const xhr: XMLHttpRequest = new XMLHttpRequest();
      xhr.open('PUT', url, true);
      xhr.setRequestHeader('Content-Type', attachment.type);

      xhr.upload.addEventListener('progress', (event: ProgressEvent): void => {
        if (event.lengthComputable) {
          const progress: number = (event.loaded / event.total) * 100;
          observer.next({ progress: Math.round(progress) });
        }
      });

      xhr.onload = (): void => {
        if (xhr.status >= 200 && xhr.status < 300) {
          observer.next({ success: true });
          observer.complete();
        } else {
          observer.error(xhr.statusText);
        }
      };

      xhr.onerror = (): void => {
        observer.error('An error occurred while uploading the attachment.');
      };

      xhr.send(attachment);

      // Cleanup function
      return (): void => {
        xhr.abort();
      };
    });
  }

  public downloadChatHistory(body: VinhubChatHistoryRequest): Observable<VinhubChatHistoryResponse> {
    return this.http.post<VinhubChatHistoryResponse>(`${environment.apiEndpoint}/chat/b2b/data/export`, body);
  }

  public renameAttachment(id: number, attachmentName: string): Observable<void> {
    const name: { name: string } = { name: attachmentName };

    return this.http.put<void>(`${environment.apiEndpoint}/chat/b2b/attachment/${id}/rename`, name);
  }
}
