<div class="verify-mandate-modal-container">
  @if (brokerMandateApprovalModel || user?.hasPoolMandate) {
    <a
      class="btn btn-white-outline btn-rounded d-flex d-md-none align-items-center justify-content-center w-100 mb-15"
      (click)="openMewBrokerMandateModal(newBrokerMandateModal)"
    >
      <i class="bi bi-clipboard-check text-20"></i>

      <span class="ml-2">Weiteres</span>
    </a>

    <button
      class="d-none d-md-flex btn btn-block btn-sm btn-rounded justify-content-center"
      [ngClass]="{
        'btn-white-outline': router.url.includes('/broker-mandate-approvals'),
        'yellow-btn':
          brokerMandateApprovalModel?.status === 'PENDING' && !router.url.includes('/broker-mandate-approvals'),
        'green-btn':
          brokerMandateApprovalModel?.status !== 'PENDING' && !router.url.includes('/broker-mandate-approvals')
      }"
      type="button"
      (click)="openMewBrokerMandateModal(newBrokerMandateModal)"
    >
      @if (router.url.includes('/broker-mandate-approvals')) {
        <i class="bi bi-download mr-2"></i>
      } @else {
        <i class="bi bi-clipboard-check mr-1"></i>
      }

      <span>
        @if (brokerMandateApprovalModel?.status === 'PENDING') {
          Pr&uuml;fen
        } @else {
          Maklermandat
        }
      </span>
    </button>
  }

  @if (!brokerMandateApprovalModel && !user?.hasPoolMandate) {
    @if (userDetailsModalActive) {
      <button
        class="btn btn-white-outline btn-rounded d-flex align-items-center justify-content-center py-10 w-100 mb-15"
        type="button"
        style="border: 1px solid"
        (click)="showResetStatusModal()"
      >
        <i class="bi bi-download mr-2"></i>
        <span>Kein Mandat</span>
      </button>
    } @else {
      <button
        class="d-flex d-md-none btn btn-white-outline align-items-center justify-content-center"
        style="min-width: 40px; height: 40px"
        (click)="showResetStatusModal()"
      >
        <!--      <i class="bi bi-clipboard-check"></-->
        <i class="bi bi-download mr-2"></i>
      </button>

      <button
        class="d-none d-md-flex btn btn-block btn-sm btn-rounded btn-white-outline justify-content-center"
        type="button"
        style="border: 1px solid; width: 140px"
        (click)="showResetStatusModal()"
      >
        <i class="bi bi-download mr-2"></i>
        <span>Kein Mandat</span>
      </button>
    }
  }
</div>

<ng-template #newBrokerMandateModal>
  <div class="d-flex d-md-none justify-content-center pt-10" (click)="modalRef.hide()">
    <img src="https://static-assets.vinlivt.de/icons/misc/mobile-gray-line.png" alt="line" />
  </div>

  <div class="modal-body">
    <div class="row mb-30">
      <div class="col-6">
        <div class="section-title">
          Maklervertrag
          @if (brokerMandateApprovalModel?.status === 'PENDING') {
            <span>&nbsp;pr&uuml;fen</span>
          }
        </div>
      </div>

      @if (brokerMandateApprovalModel?.status === 'APPROVED') {
        <div class="col-6">
          <div class="d-flex align-items-center justify-content-end">
            <div class="green-check">
              <i class="bi bi-check-lg text-white"></i>
            </div>
            <div class="green-text">Angenommen</div>
          </div>
        </div>
      }
    </div>

    <div class="row mb-20">
      <div class="col-md-6 d-flex align-items-center">
        <img
          style="border-radius: 50%"
          ngSrc="{{ user.profilePictureUrl ?? 'https://static-assets.vinlivt.de/img/misc/bird.png' }}"
          alt="advisor avatar"
          width="35"
          height="35"
        />

        <a
          href="javascript:void(0)"
          [routerLink]="['/clients/detail', user?.id]"
          (click)="closeModal()"
          style="bottom: 22px; left: 9px"
          class="font-w600 cursor-pointer ml-10"
        >
          {{ user?.firstname }} {{ user?.lastname }}
        </a>
      </div>

      <div class="col-md-6 text-right my-auto text-right">
        @if (!user?.hasPoolMandate && brokerMandateApprovalModel) {
          <a class="btn btn-rounded btn-white-outline" href="tel: {{ '+49' + user?.mobile }}">
            <i class="bi bi-telephone mr-1"></i>
            <span>{{ '+49' + user?.mobile }}</span>
          </a>
        }

        @if (
          (user.hasPoolMandate && brokerMandateApprovalModel) || (user.hasPoolMandate && !brokerMandateApprovalModel)
        ) {
          @if (user?.poolCrmLink) {
            <a
              target="_blank"
              class="btn btn-noborder btn-sm btn-rounded btn-action ml-2 text-white"
              [href]="user?.poolCrmLink"
            >
              <img src="https://static-assets.vinlivt.de/icons/line-icons/svg/link.svg" class="mr-5 link-icon" />
              <span>Poolansicht</span>
            </a>
          }
        }
      </div>
    </div>

    @if (brokerMandateApprovalModel) {
      <div class="row">
        <div class="col-4 pr-2">
          <div class="mandate-file-block gray-border bg-white br-16 py-10 px-20">
            <div class="row">
              <div class="col-12 text-center">
                <i class="bi bi-filetype-pdf" style="font-size: 40px"></i>
              </div>

              <div class="col-12 text-center my-10">
                <div class="text-secondary">Maklervertrag</div>
              </div>

              <div class="col-6">
                <a href="javascript:void(0)" (click)="preview('MANDATE')">
                  <i class="bi bi-eye text-20"></i>
                </a>
              </div>

              <div class="col-6 text-right">
                <a href="javascript:void(0)" (click)="downloadPdf('MANDATE')">
                  <i class="bi bi-download text-20"></i>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-4 px-2">
          <div class="mandate-file-block gray-border bg-white br-16 py-10 px-20">
            <div class="row">
              <div class="col-12 text-center">
                <i class="bi bi-filetype-pdf" style="font-size: 40px"></i>
              </div>

              <div class="col-12 text-center my-10">
                <div class="text-secondary">Maklervollmacht</div>
              </div>

              <div class="col-6">
                <a href="javascript:void(0)" (click)="preview('POWER_OF_ATTORNEY')">
                  <i class="bi bi-eye text-20"></i>
                </a>
              </div>

              <div class="col-6 text-right">
                <a href="javascript:void(0)" (click)="downloadPdf('POWER_OF_ATTORNEY')">
                  <i class="bi bi-download text-20"></i>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-4 pl-2">
          <div class="mandate-file-block gray-border bg-white br-16 py-10 px-20">
            <div class="row">
              <div class="col-12 text-center">
                <i class="bi bi-filetype-pdf" style="font-size: 40px"></i>
              </div>

              <div class="col-12 text-center my-10">
                <div class="text-secondary">Datenschutz</div>
              </div>

              <div class="col-6">
                <a href="javascript:void(0)" (click)="preview('PRIVACY_CONSENT')">
                  <i class="bi bi-eye text-20"></i>
                </a>
              </div>

              <div class="col-6 text-right">
                <a href="javascript:void(0)" (click)="downloadPdf('PRIVACY_CONSENT')">
                  <i class="bi bi-download text-20"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    }

    @if (brokerMandateApprovalModel?.status !== 'PENDING' || user?.hasPoolMandate) {
      <div class="row">
        <div class="col-md-12">
          <div class="row text-center">
            @if (user?.hasPoolMandate && !brokerMandateApprovalModel) {
              <div class="col-md-12 mt-30">
                <img src="https://static-assets.vinlivt.de/icons/misc/cards-pdf.png" width="150" alt="pdf's img" />
                <div class="font-size-h5 mt-20 mb-2">Vertragsdaten liegen im Pool-System</div>
                <div class="font-size-h6 text-secondary">Bitte den Kunden im Pool-System öffnen.</div>
              </div>
            }
            <!--            @else {-->
            <!--              @if (brokerMandateApprovalModel?.status === 'APPROVED') {-->
            <!--                <div class="col-md-12">-->
            <!--                  <h5>Mandat wurde erfolgreich best&auml;tigt</h5>-->
            <!--                  <img-->
            <!--                    src="https://static-assets.vinlivt.de/icons/premium/clay/320/Check%201.png"-->
            <!--                    alt="check"-->
            <!--                    width="150"-->
            <!--                  />-->
            <!--                </div>-->
            <!--              }-->

            <!--              @if (brokerMandateApprovalModel?.status.startsWith('REJECTED')) {-->
            <!--                <div class="col-md-12 my-30">-->
            <!--                  <h5>Mandat wurde leider abgelehnt</h5>-->
            <!--                  <img-->
            <!--                    src="https://static-assets.vinlivt.de/icons/premium/clay/320/Multi%201.png"-->
            <!--                    alt="multi"-->
            <!--                    width="150"-->
            <!--                  />-->
            <!--                </div>-->
            <!--              }-->

            <!--              @if (brokerMandateApprovalModel?.status.startsWith('REVOCATION')) {-->
            <!--                <div class="col-md-12 my-30">-->
            <!--                  <h5>Mandat wurde leider widerrufen</h5>-->
            <!--                  <img-->
            <!--                    src="https://static-assets.vinlivt.de/icons/premium/clay/320/Info%201.png"-->
            <!--                    alt="info"-->
            <!--                    width="150"-->
            <!--                  />-->
            <!--                </div>-->
            <!--              }-->
            <!--            }-->
          </div>
        </div>
      </div>
    }

    @if (brokerMandateApprovalModel?.status === 'PENDING') {
      <div class="row">
        <div class="col-md-12">
          <table class="table table-vcenter table-borderless full-width-table mt-20 rounded-corner-table">
            <tbody>
              <tr>
                <td [ngClass]="{ 'selected-no-label': status === 'WRONG-DATA' }">
                  <label class="css-control css-control-danger css-radio">
                    <input
                      (change)="setStatus('REJECTED_WRONG_DATA')"
                      type="radio"
                      class="css-control-input"
                      name="mandate-acceptance-status"
                    />
                    <span class="css-control-indicator"></span> Ablehnen
                  </label>
                </td>
                <td class="text-muted text-right">"Kundendaten ung&uuml;ltig"</td>
              </tr>

              <tr>
                <td [ngClass]="{ 'selected-no-label': status === 'WRONG-SIGNATURE' }">
                  <label class="css-control css-control-danger css-radio">
                    <input
                      (change)="setStatus('REJECTED_WRONG_SIGNATURE')"
                      type="radio"
                      class="css-control-input"
                      name="mandate-acceptance-status"
                    />
                    <span class="css-control-indicator"></span> Ablehnen
                  </label>
                </td>
                <td class="text-muted text-right">"Kundenunterschrift ung&uuml;ltig"</td>
              </tr>

              <tr>
                <td [ngClass]="{ 'selected-yes-label': status === 'ACCEPTED' }">
                  <label class="css-control css-control-success css-radio">
                    <input
                      (change)="setStatus('APPROVED')"
                      type="radio"
                      class="css-control-input"
                      name="mandate-acceptance-status"
                    />
                    <span class="css-control-indicator"></span> Akzeptieren
                  </label>
                </td>
                <td class="text-muted text-right">"Mandat annehmen"</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    }

    <div class="dropdown-divider mt-15"></div>

    <div class="row mt-15">
      @if (user?.isPoolClient && !user?.hasPoolMandate && !brokerMandateApprovalModel) {
        <div class="col-md-6">
          <button
            type="button"
            (click)="synchroniseMandate()"
            class="btn btn-sm btn-rounded btn-action-secondary min-width-150"
          >
            @if (isSynching) {
              <i class="fa fa-spinner fa-spin mr-10"></i>
            } @else {
              <span><i class="bi bi-arrow-clockwise mr-5"></i> Synchronisieren </span>
            }
          </button>
        </div>

        <div class="col-md-6 text-right">
          @if (
            ((!brokerMandateApprovalModel && user?.hasPoolMandate) ||
              brokerMandateApprovalModel?.status === 'REVOCATION_REQUESTED' ||
              brokerMandateApprovalModel?.status === 'APPROVED') &&
            !showSpinner
          ) {
            <a href="javascript:void(0)" (click)="closeModal()">Schlie&szlig;en</a>
          }
        </div>
      }

      @if (user?.hasPoolMandate && !brokerMandateApprovalModel) {
        <div class="col-6">
          <button type="button" (click)="resetMandateStatus(false)" class="btn btn-sm btn-rounded orange-btn">
            <span>Zurücksetzen </span>
            @if (resetLoading) {
              <i class="fa fa-spinner fa-spin mr-1"></i>
            }
          </button>
        </div>

        <div class="col-6 d-flex justify-content-end align-items-center">
          <a href="javascript:void(0)" (click)="closeModal()">Schlie&szlig;en</a>
        </div>
      }

      <div class="col-12 d-flex justify-content-between">
        <div class="d-flex align-items-center">
          @if (brokerMandateApprovalModel) {
            <i class="bi bi-calendar-event text-secondary mr-1"></i>

            @if (brokerMandateApprovalModel?.approvedAt) {
              <div class="text-secondary">{{ brokerMandateApprovalModel?.approvedAt | date: 'dd.MM.yyyy' }}</div>
            } @else {
              <div class="text-secondary">{{ brokerMandateApprovalModel?.created | date: 'dd.MM.yyyy' }}</div>
            }

            <i class="bi bi-clock text-secondary mr-1 ml-20"></i>

            @if (brokerMandateApprovalModel?.approvedAt) {
              <div class="text-secondary">{{ brokerMandateApprovalModel?.approvedAt | date: 'HH:mm' }} Uhr</div>
            } @else {
              <div class="text-secondary">{{ brokerMandateApprovalModel?.created | date: 'HH:mm' }} Uhr</div>
            }
          }
        </div>

        @if (brokerMandateApprovalModel?.status === 'PENDING' || showSpinner) {
          <div class="d-flex">
            <button [disabled]="showSpinner" class="btn cursor-pointer" (click)="closeModal()">Abbrechen</button>

            <button
              [disabled]="!status || showSpinner"
              class="btn btn-action btn-rounded btn-noborder ml-20"
              (click)="saveDecision()"
            >
              <span> Best&auml;tigen </span>

              @if (showSpinner) {
                <span class="ml-10">
                  <div class="spinner-border text-white" role="status" style="width: 14px; height: 14px">
                    <span class="sr-only">Bitte warten...</span>
                  </div>
                </span>
              }
            </button>
          </div>
        }

        @if (brokerMandateApprovalModel?.status === 'REVOCATION_REQUESTED') {
          <div>
            <button class="btn cursor-pointer" (click)="closeModal()">Abbrechen</button>
            <button class="btn btn-action btn-rounded btn-noborder ml-20" (click)="setAcknoledged()">Gelesen</button>
          </div>
        }

        @if (
          (brokerMandateApprovalModel?.status === 'REVOCATION_REQUESTED' ||
            brokerMandateApprovalModel?.status === 'REJECTED_WRONG_DATA' ||
            brokerMandateApprovalModel?.status === 'REJECTED_WRONG_SIGNATURE' ||
            brokerMandateApprovalModel?.status === 'APPROVED') &&
          !showSpinner
        ) {
          <div>
            <a href="javascript:void(0)" (click)="closeModal()">Schlie&szlig;en</a>
          </div>
        }
      </div>
    </div>
  </div>
</ng-template>
