import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { SharedModule } from '../shared/shared.module';

import { UserSidebarModalComponent } from './user-sidebar-modal/user-sidebar-modal.component';
import { BrokerMandateModalComponent } from '../shared/modals/broker-mandate-modal/broker-mandate-modal.component';
import { MobileBottomNavigationComponent } from './mobile-bottom-navigation/mobile-bottom-navigation.component';
import { SidebarComponent } from './sidebar/app-sidebar.component';
import { HeaderComponent } from './header/app-header.component';
import { LayoutComponent } from './app-layout.component';
import { UserSidebarComponent } from './user-sidebar/user-sidebar.component';

import { ROUTES } from './layout.routes';
import { FormsModule } from '@angular/forms';
import { PoolSynchModalComponent } from '../shared/modals/pool-synch-modal/pool-synch-modal.component';

@NgModule({
  declarations: [
    LayoutComponent,
    HeaderComponent,
    SidebarComponent,
    UserSidebarComponent,
    MobileBottomNavigationComponent,
    UserSidebarModalComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    ROUTES,
    NgOptimizedImage,
    NgxSkeletonLoaderModule,
    BrokerMandateModalComponent,
    FormsModule,
    PoolSynchModalComponent,
  ],
  exports: [LayoutComponent],
})
export class LayoutModule {}
