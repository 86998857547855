<div class="user-sidebar-modal-container">
  <div class="modal-body p-0">
    <div class="content-header content-header-fullrow">
      <div class="row align-items-center mb-10">
        <div class="col-6">
          <div class="">Einklappen</div>
        </div>

        <div class="col-6 d-flex justify-content-end">
          <button type="button" class="btn btn-circle" (click)="closeFlyoutMenu()">
            <i class="bi bi-x-lg"></i>
          </button>
        </div>
      </div>

      <div class="row">
        <div class="col-10">
          <div class="d-flex align-items-center">
            <img
              class="mr-2"
              style="border-radius: 50%"
              src="{{ advisor?.profilePictureUrl ?? 'https://static-assets.vinlivt.de/img/misc/fox.png' }}"
              alt="advisor avatar"
              width="30"
              height="30"
            />

            <div class="d-flex flex-column">
              <div class="parent-truncate">
                <div class="child-truncate">{{ advisor?.company?.name }}</div>
              </div>

              <div class="text-12 text-secondary d-flex flex-wrap align-items-center">
                <span *appHasGroup="[Groups.COMPANY_ADMIN]" class="font-w300">Administrator</span>
                @if (
                  authService.isInGroup(Groups.ADVISOR) ||
                  authService.isInGroup(Groups.BACKOFFICE) ||
                  authService.isInGroup(Groups.TRAINEE)
                ) {
                  <span *appHasGroup="[Groups.COMPANY_ADMIN]" class="mx-1">&#183;</span>
                }

                <span *appHasGroup="[Groups.ADVISOR]" class="font-w300">Berater</span>
                @if (authService.isInGroup(Groups.BACKOFFICE) || authService.isInGroup(Groups.TRAINEE)) {
                  <span *appHasGroup="[Groups.ADVISOR]" class="mx-1">&#183;</span>
                }

                <span *appHasGroup="[Groups.BACKOFFICE]" class="font-w300">Backoffice</span>
                @if (authService.isInGroup(Groups.TRAINEE)) {
                  <div *appHasGroup="[Groups.BACKOFFICE]" class="mx-1">&#183;</div>
                }

                <span *appHasGroup="[Groups.TRAINEE]" class="font-w300">Tippgeber</span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-2 px-0 d-flex align-items-center">
          <i *appHasGroup="[Groups.COMPANY_ADMIN]" class="bi bi-star-fill text-12 mr-1" style="color: #ffc75f"></i>
          <i
            *appHasGroup="[Groups.ADVISOR]"
            class="bi bi-star-fill text-12 mr-1"
            style="color: var(--purple-color)"
          ></i>
          <i
            *appHasGroup="[Groups.BACKOFFICE]"
            class="bi bi-star-fill text-12 mr-1"
            style="color: var(--blue-color)"
          ></i>
          <i *appHasGroup="[Groups.TRAINEE]" class="bi bi-star-fill text-12 text-black mr-1"></i>
        </div>
      </div>
    </div>

    <div class="pull-r-l mt-30 mb-20">
      <div class="content-side menu-item">
        <label class="font-w400 text-secondary" for="pin">{{ advisor?.firstname }} {{ advisor?.lastname }}-ID</label>

        <div class="input-group">
          <input type="text" name="pin" id="pin" class="form-control" [value]="pin" disabled />
          <div class="input-group-append">
            <span class="input-group-text">
              <img
                src="https://static-assets.vinlivt.de/icons/line-icons/svg/clipboard.svg"
                (click)="copyPin()"
                class="cursor-pointer"
                alt="clip icon"
              />
            </span>
          </div>
        </div>
      </div>

      <!--      <div class="row">-->
      <!--        <div class="col-md-12 mb-10 text-center">Ihre Berater-ID</div>-->
      <!--      </div>-->

      <!--      <div class="row">-->
      <!--        <div class="col-md-12 text-center">-->
      <!--          @for (p of pin; track p) {-->
      <!--            <span class="pin-container">{{ p }}</span>-->
      <!--          }-->

      <!--          <img-->
      <!--            (click)="copyPin()"-->
      <!--            src="https://static-assets.vinlivt.de/icons/line-icons/svg/clipboard.svg"-->
      <!--            alt="Kopieren"-->
      <!--            class="ml-5 cursor-pointer"-->
      <!--          />-->
      <!--        </div>-->
      <!--      </div>-->
    </div>

    <div class="pull-r-l">
      <div class="block-header bg-body-light">
        <h3 class="block-title text-black font-w400">Kontakt</h3>
      </div>
      <div class="content-side menu-item">
        <a
          class="dropdown-item font-w400 mb-0 py-10"
          href="javascript:void(0)"
          (click)="goToOtherExternalUrl('https://1jd89nuf213.typeform.com/to/j7WQ75Q0')"
        >
          <i class="bi bi-ticket-detailed mr-2"></i>
          Support Ticket
        </a>

        <a class="dropdown-item font-w400 mb-0 py-10" href="tel:+49 89 958 618 94">
          <i class="bi bi-headset mr-2"></i>
          +49 157 3067 7053
        </a>
      </div>
    </div>

    @if (innerWidth < 650) {
      <div class="pull-r-l">
        <div class="block-header bg-body-light">
          <h3 class="block-title text-black font-w400">Bereiche</h3>
        </div>

        <div class="content-side menu-item">
          <a class="dropdown-item font-w400 mb-0 py-10" (click)="navigateTo('analyse')">
            <img
              class="mr-2"
              src="https://static-assets.vinlivt.de/icons/line-icons/svg/chart.svg"
              width="14"
              alt="nav icon"
            />
            Analysen
          </a>

          @if (authService.isInGroup(Groups.ADVISOR) || authService.isInGroup(Groups.TRAINEE)) {
            <a class="dropdown-item font-w400 mb-0 py-10" (click)="navigateToMediaStudio()">
              <img
                class="mr-2"
                src="https://static-assets.vinlivt.de/icons/line_icons_2.0/Social/Youtube.svg"
                width="14"
                alt="nav icon"
              />
              Media Studio

              @if (!authService.hasRole(Roles.MEDIA_STUDIO)) {
                <span class="ml-15">
                  <i class="bi bi-award"></i>
                </span>
              }
            </a>

            <a
              *appHasRole="[Roles.CAMPAIGN]"
              class="dropdown-item font-w400 mb-0 py-10"
              (click)="navigateTo('/campaign/overview')"
            >
              <img
                class="mr-2"
                src="https://static-assets.vinlivt.de/icons/line_icons_2.0/System/Radio.svg"
                width="14"
                alt="nav icon"
              />
              Kampagnen
            </a>
          }
        </div>
      </div>
    }

    <div class="pull-r-l">
      <div class="block-header bg-body-light">
        <h3 class="block-title text-black font-w400">Mein Profil</h3>
      </div>
      <div class="content-side menu-item">
        <a class="dropdown-item font-w400 mb-0 py-10" (click)="navigateTo('/profile/settings')">
          <i class="bi bi-gear mr-2"></i>
          Einstellungen
        </a>

        <!--        <a *appHasRole="[Roles.COMPANY_ADMIN]" class="dropdown-item font-w400" (click)="navigateTo('/profile-tab')">-->
        <!--          <i class="bi bi-sliders mr-2"></i>-->
        <!--          Einstellungen-->
        <!--        </a>-->

        <!--        <a *appHasRole="[Roles.MANDATE]" class="dropdown-item font-w400" (click)="navigateTo('/mandate')">-->
        <!--          <i class="bi bi-ui-checks mr-2"></i>-->
        <!--          Maklervertrag-->
        <!--        </a>-->

        <!--        @if (authService.isInGroup(Groups.ADVISOR) || authService.isInGroup(Groups.TRAINEE)) {-->
        <!--          <a class="dropdown-item font-w400" (click)="navigateToPool()">-->
        <!--            <i class="bi bi-back mr-2"></i>-->
        <!--            Maklerpool-->

        <!--            @if (!authService.hasRole(Roles.POOL_CONNECTION)) {-->
        <!--              <span class="ml-15">-->
        <!--                <i class="bi bi-award"></i>-->
        <!--              </span>-->
        <!--            }-->
        <!--          </a>-->
        <!--        }-->

        <!--        <a-->
        <!--          *appHasRole="[Roles.COMPANY_ADMIN]"-->
        <!--          class="dropdown-item pointer-cursor font-w400"-->
        <!--          (click)="navigateTo('/subscription-manager')"-->
        <!--        >-->
        <!--          <i class="bi bi-credit-card mr-2"></i>-->
        <!--          Abonnements-->
        <!--        </a>-->

        <!--        <a-->
        <!--          *appHasRole="[Roles.DATA_IMPORTER]"-->
        <!--          class="dropdown-item font-w400"-->
        <!--          (click)="navigateTo('/data-advisors-table')"-->
        <!--        >-->
        <!--          <i class="bi bi-filetype-csv mr-2"></i>-->
        <!--          Importer-->
        <!--        </a>-->
      </div>
    </div>

    <div class="pull-r-l">
      <div class="block-header bg-body-light">
        <h3 class="block-title text-black font-w400">Sicherheit</h3>
      </div>
      <div class="content-side menu-item">
        <a
          class="dropdown-item font-w400 mb-0 py-10"
          href="javascript:void(0)"
          (click)="
            goToOtherExternalUrl(
              'https://vinlivt-data-room.notion.site/Vinlivt-Datenschutzrichtlinien-62f1101c56c748a4a573b6ac6c81c45e'
            )
          "
        >
          <i class="bi bi-shield-lock mr-2"></i>
          Datenschutzinformation
        </a>

        <a
          class="dropdown-item font-w400 mb-0 py-10"
          href="javascript:void(0)"
          (click)="
            goToOtherExternalUrl(
              'https://vinlivt-data-room.notion.site/Vinlivt-Nutzungsbedingungen-c9ec38b35b6a409aa5c43369eb7c8eb3'
            )
          "
        >
          <i class="bi bi-mouse3 mr-2"></i>
          Nutzungsbedingungen
        </a>

        <a
          class="dropdown-item font-w400 mb-0 py-10"
          href="javascript:void(0)"
          (click)="
            goToOtherExternalUrl(
              'https://vinlivt-data-room.notion.site/Vinlivt-Leistungsschein-6e738ea9483c4c96999c298a02fae8eb'
            )
          "
        >
          <i class="bi bi-book mr-2"></i>
          Leistungsschein
        </a>

        <!-- <a
          class="dropdown-item"
          href="javascript:void(0)"
          (click)="goToOtherExternalUrl('https://www.vinlivt.de/kontakt/')"
        >
           Beratung
        </a>
        <a
          class="dropdown-item"
          href="javascript:void(0)"
          (click)="goToOtherExternalUrl('https://www.vinlivt.de/ueber-uns/')"
        >
          Team
        </a>
        <a
          class="dropdown-item"
          href="javascript:void(0)"
          (click)="goToOtherExternalUrl('https://www.vinlivt.de/datenschutz/')"
        >
          Datenschutz
        </a>
        <a class="dropdown-item" href="javascript:void(0)" (click)="goToOtherExternalUrl('https://www.vinlivt.de/agb/')">
          AGB
        </a> -->
      </div>
    </div>

    <div class="content-side">
      <div class="block pull-t pull-r-l">
        <div class="block-content block-content-full block-content-sm bg-white">
          <button type="button" class="btn btn-block btn-rounded btn-outline-secondary" (click)="logout()">
            <span>Logout</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
