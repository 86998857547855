<div class="confirmation-modal-container">
  <div class="d-flex d-md-none justify-content-center pt-10" (click)="bsModalRef.hide()">
    <img src="https://static-assets.vinlivt.de/icons/misc/mobile-gray-line.png" alt="line" />
  </div>

  <div class="modal-header no-border-bottom">
    <h4 class="modal-title pull-left theme-color">{{ confirmationTitle }}</h4>
    <button
      type="button"
      class="d-none d-md-flex close pull-right pointer-cursor"
      aria-label="Close"
      (click)="cancel()"
    >
      <span aria-hidden="true" class="visually-hidden theme-color">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-12 theme-color">{{ confirmationMessage }}</div>
    </div>

    <div class="row mt-30">
      <div class="col-12 text-right">
        <a href="javascript:void(0)" class="btn theme-secondary-color" (click)="cancel()">Abbrechen</a>

        @if (danger && !success) {
          <button class="btn btn-action-secondary btn-rounded ml-5" [disabled]="disableButton" (click)="confirm()">
            @if (actionLabelBtn) {
              <span>{{ actionLabelBtn }}</span>
            } @else {
              <span>L&ouml;schen</span>
            }

            @if (disableButton) {
              <span class="ml-10">
                <div class="spinner-border text-secondary" role="status" style="width: 14px; height: 14px">
                  <span class="sr-only">Bitte warten...</span>
                </div>
              </span>
            }
          </button>
        } @else if (!danger && success) {
          <!-- success btn -->
          <button class="btn btn-action-secondary btn-rounded ml-5" (click)="confirm()" [disabled]="disableButton">
            <!--      <i class="bi bi-check"></i>-->
            @if (actionLabelBtn) {
              <span>{{ actionLabelBtn }}</span>
            } @else {
              <span>L&ouml;schen</span>
            }

            @if (disableButton) {
              <span class="ml-10">
                <div class="spinner-border text-secondary" role="status" style="width: 14px; height: 14px">
                  <span class="sr-only">Bitte warten...</span>
                </div>
              </span>
            }
          </button>
        } @else {
          <!-- default btn -->
          <button class="btn btn-action-secondary btn-rounded ml-5" (click)="confirm()" [disabled]="disableButton">
            @if (actionLabelBtn) {
              <span>{{ actionLabelBtn }}</span>
            } @else {
              <span>L&ouml;schen</span>
            }

            @if (disableButton) {
              <span class="ml-10">
                <div class="spinner-border text-secondary" role="status" style="width: 14px; height: 14px">
                  <span class="sr-only">Bitte warten...</span>
                </div>
              </span>
            }
          </button>
        }
      </div>
    </div>
  </div>
</div>
