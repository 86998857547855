import { Component, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  standalone: true,
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmationModalComponent {
  @Output() action = new EventEmitter();

  public confirmationMessage: string;
  public confirmationTitle: string;
  public actionLabelBtn: string;
  public disableButton: boolean = false;

  public danger: boolean;
  public success: boolean;

  constructor(protected bsModalRef: BsModalRef) {}

  public confirm(): void {
    this.action.emit(true);
  }

  public cancel(): void {
    this.action.emit(false);
  }
}
